<template>
  <div>
    <ManageBanners />
  </div>
</template>
<script>
import ManageBanners from "@/components/Banners/ManageBanners";
export default {
  components: {
    ManageBanners,
  },
  created() {
  },
};
</script>
